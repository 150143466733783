import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 554.000000 554.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,554.000000) scale(0.100000,-0.100000)">
      <path d="M2652 3870 c-172 -19 -315 -68 -462 -158 l-35 -22 48 0 c32 0 66 9
105 29 139 69 292 104 457 104 228 0 412 -56 600 -183 230 -156 388 -399 446
-685 16 -78 16 -282 0 -365 -28 -150 -95 -311 -178 -428 -31 -43 -47 -77 -50
-105 l-5 -42 31 35 c118 134 217 341 252 530 18 95 15 309 -5 405 -83 386
-343 690 -702 821 -163 59 -343 82 -502 64z"/>
<path d="M2025 3619 c-11 -17 1 -22 61 -25 46 -2 61 -7 85 -30 l29 -28 0 -543
c0 -596 0 -597 -59 -627 -16 -9 -46 -16 -65 -16 -27 0 -34 -3 -30 -15 9 -22
546 -22 649 0 85 18 158 54 215 106 48 44 52 45 70 24 7 -9 66 -59 132 -111
129 -105 176 -156 201 -222 74 -189 -112 -348 -317 -272 -57 22 -118 79 -137
127 -5 14 -14 58 -20 98 -8 50 -15 72 -25 72 -11 0 -14 -31 -14 -172 l0 -172
75 -22 c153 -46 326 -37 443 23 176 89 240 311 146 505 -34 69 -138 174 -291
293 -152 118 -195 188 -173 282 20 90 87 136 198 136 126 0 202 -58 233 -181
7 -27 19 -49 26 -49 10 0 13 31 13 139 l0 139 -52 14 c-31 7 -106 13 -178 13
-150 0 -224 -22 -308 -91 l-54 -44 -41 29 c-23 15 -61 36 -84 46 l-42 18 59
64 c81 89 103 139 104 233 1 59 -4 84 -21 116 -42 80 -123 127 -246 143 -105
15 -574 15 -582 0z m515 -69 c66 -19 123 -86 146 -172 26 -100 11 -196 -39
-255 -38 -43 -142 -66 -214 -47 l-23 6 0 232 c0 128 3 236 7 239 10 10 83 8
123 -3z m70 -572 c20 -8 57 -39 84 -69 121 -133 150 -338 65 -442 -42 -51 -89
-70 -174 -71 -81 0 -142 24 -162 64 -9 18 -13 97 -13 281 0 141 2 258 4 260
10 9 163 -9 196 -23z"/>
<path d="M1881 3428 c-144 -197 -212 -404 -214 -648 -1 -148 13 -240 60 -375
106 -309 370 -574 673 -678 130 -44 233 -60 380 -60 74 0 146 5 159 10 21 8
13 11 -50 21 -41 6 -117 14 -169 18 -614 39 -1062 560 -1000 1163 23 218 104
401 265 599 l26 32 -35 0 c-32 0 -39 -6 -95 -82z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
